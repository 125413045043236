import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";

export default class expand {
    constructor() {
        this.http = new HttpHandler();
    }

    /**
     * 保持会话生效
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    ping(data) {
        let url = '/api/expand/ping';
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 判断是否登录
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    isLogin(data) {
        let url = '/api/expand/isLogin';
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    getOtherData(data) {
        let url = '/api/other/getOtherData';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    delOtherData(id) {
        let url = '/api/other/del/' + id;
        return this.http.delete(url, null, HttpConstant.FORM_DATA_REQ);
    }
    insOtherData(data) {
        let url = '/api/other/insOtherData';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }
    updOtherData(data) {
        let url = '/api/other/updOtherData';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }
    getOthersById(id) {
        let url = '/api/other/getOtherData/' + id;
        return this.http.get(url, null, HttpConstant.APPLICATION_JSON_REQ);
    }
}

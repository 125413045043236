<template>
  <div class="center">
    <el-card :body-style="{ padding: '0px'}">
      <el-image class="image" :src="item.img" fit="scale-down"
                style="display: flex; justify-content: center; align-items: center;">
        <div slot="error" class="image-slot">
          <!--          <i class="el-icon-picture-outline"></i>-->
          <p class="titleImg">{{ item.title }}</p>
        </div>
      </el-image>
      <div>
        <p class="title">{{ item.title }}</p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Card-List",
  props: {
    item: {
      img: String,
      title: String,
      url: String
    }
  },
}
</script>

<style scoped>
.el-card {
  width: 100%;
  height: 180px;
}

.image {
  width: 100%;
  height: 140px;
}

.el-icon-picture-outline {
  font-size: 100px;
}

.center {
  margin: 0 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer
}

.title {
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 限制文本的行数 */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; /* 允许在单词内换行 */
}

.titleImg {
  font-size: 24px;
  text-shadow: 2px 2px 5px #666;
  font-weight: bold;
  letter-spacing: 2px;
  /*vertical-align: middle;*/
  /*text-align: center;*/
  /*margin-top: 50%;*/
}
</style>